import { useMemo, useRef, Fragment } from "react";
import { usePlainCss, useBannerButtons } from "../../hooks";
import { useBanner } from "../../contexts";
import { bannerInlineStylesScale, BANNER_SCALING_WHEN_WINDOW_WIDTH_LOWER } from "./inlineStyles";
import { BannerTooltip } from ".";
import { getOtherOptionsFromWindow } from "../../utils";
import { useRect } from "@reach/rect";
import { Portal } from "..";
var counter = 0;

var BannerCloseIcon = function BannerCloseIcon() {
  var idCloseIcon = useMemo(function () {
    return "react-close-icon-".concat((counter++).toString(36));
  }, []);
  /**
   * Why two containers for a simple close icon? I need to explain something technical here.
   * Our complete banner/dialog is tainted with `overflow:hidden` due to flickering effects
   * with animate.css etc. We will now create an invisible element with the placeholder,
   * we will then read its positioning (rect) and generate the visible close icon based on this position.
   */

  var idCloseIconPlaceholder = useMemo(function () {
    return "react-close-icon-".concat((counter++).toString(36));
  }, []);
  var ref = useRef();
  var rect = useRect(ref);

  var _useBanner = useBanner(),
      _useBanner$headerDesi = _useBanner.headerDesign,
      fontSize = _useBanner$headerDesi.fontSize,
      fontColor = _useBanner$headerDesi.fontColor,
      acceptEssentials = _useBanner.texts.acceptEssentials,
      mobile = _useBanner.mobile,
      activeAction = _useBanner.activeAction,
      pageRequestUuid4 = _useBanner.pageRequestUuid4,
      _useBanner$buttonClic = _useBanner.buttonClicked,
      buttonClicked = _useBanner$buttonClic === void 0 ? "" : _useBanner$buttonClic;

  var width = bannerInlineStylesScale(mobile, pageRequestUuid4, fontSize);
  var thickness = 1;

  var _useBannerButtons = useBannerButtons(),
      buttonClickedCloseIcon = _useBannerButtons.buttonClickedCloseIcon,
      closeIcon = _useBannerButtons.closeIcon;

  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      _getOtherOptionsFromW2 = _getOtherOptionsFromW.bannerI18n,
      close = _getOtherOptionsFromW2.close,
      closeWithoutSaving = _getOtherOptionsFromW2.closeWithoutSaving;

  var tooltipText = activeAction ? activeAction === "change" ? closeWithoutSaving : close : acceptEssentials;
  usePlainCss("#".concat(idCloseIconPlaceholder, " {\n position: absolute;\n top: 50%;\n right: 0;\n margin-top: calc(").concat(width, "px / 2 * -1);\n display: block;\n width: ").concat(width, "px;\n height: ").concat(width, "px; \n pointer-events: none;\n}\n\n#").concat(idCloseIcon, " {\n position: fixed !important;\n ").concat(rect ? "top: ".concat(rect.y, "px;left: ").concat(rect.x, "px;display:block;") : "display:none;", "\n width: ").concat(width, "px;\n height: ").concat(width, "px;\n cursor: pointer;\n z-index: 99;\n ").concat(buttonClicked === buttonClickedCloseIcon ? "outline: rgb(255, 94, 94) solid 5px;" : "", "\n}\n#").concat(idCloseIcon, ":after {\n  width: auto !important;\n  white-space: nowrap !important;\n}\n#").concat(idCloseIcon, " > span:before,#").concat(idCloseIcon, " > span:after{\n position: absolute;\n top: 50%;\n left: 50%;\n width: ").concat(thickness, "px;\n height: ").concat(width, "px;\n background-color: ").concat(fontColor, ";\n transform: rotate(45deg) translate(-50%, -50%);\n transform-origin: top left;\n transition: all 420ms;\n content: '';\n opacity: 0.5;\n pointer-events: none;\n}\n#").concat(idCloseIcon, " > span:after{\n transform: rotate(-45deg) translate(-50%, -50%);\n}\n#").concat(idCloseIcon, " > span:hover:before,#").concat(idCloseIcon, " > span:hover:after{\n opacity: 1;\n width: ").concat(thickness + 1, "px;\n}"));
  var container = document.getElementById(pageRequestUuid4);
  return h(Fragment, null, h("div", {
    id: idCloseIconPlaceholder,
    ref: ref
  }), (rect === null || rect === void 0 ? void 0 : rect.y) > 0 && h(Portal, {
    renderInContainer: container
  }, h(BannerTooltip, {
    title: tooltipText,
    id: idCloseIcon,
    onClick: closeIcon,
    position: rect.y > 50 ? "top-left" : "left",
    always: (container === null || container === void 0 ? void 0 : container.clientWidth) < BANNER_SCALING_WHEN_WINDOW_WIDTH_LOWER
  }, h("span", null))));
};

export { BannerCloseIcon };