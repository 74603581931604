import { bannerInlineStylesScale } from "..";

function bannerHeaderInlineStyleSeparator(_ref) {
  var type = _ref.layout.type,
      design = _ref.design,
      _ref$headerDesign = _ref.headerDesign,
      borderWidth = _ref$headerDesign.borderWidth,
      borderColor = _ref$headerDesign.borderColor,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4,
      headerContainer = _ref.renderedHeights.headerContainer;
  var useDesignBorderWidth = bannerInlineStylesScale(mobile, pageRequestUuid4, design.borderWidth);
  var style = {
    height: bannerInlineStylesScale(mobile, pageRequestUuid4, +borderWidth),
    background: borderColor,
    position: "sticky",
    top: +headerContainer,
    zIndex: 9
  };

  if (bannerInlineStylesScale(mobile, pageRequestUuid4, type, "banner") === "dialog" && useDesignBorderWidth > 0) {
    style.borderLeft = "".concat(useDesignBorderWidth, "px solid ").concat(design.borderColor);
    style.borderRight = style.borderLeft;
  }

  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-header-separator",
    style: style
  };
}

export { bannerHeaderInlineStyleSeparator };