import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { bannerInlineStylesScale } from "..";
import { hexToRgb } from "../../../../utils";
import { BUTTONS_WIDTH } from "./";

function bannerInlineStylesRightSide(_ref) {
  var _style;

  var _ref$layout = _ref.layout,
      type = _ref$layout.type,
      dialogMaxWidth = _ref$layout.dialogMaxWidth,
      _ref$design = _ref.design,
      borderWidth = _ref$design.borderWidth,
      bg = _ref$design.bg,
      fontColor = _ref$design.fontColor,
      headerDesign = _ref.headerDesign,
      footerDesign = _ref.footerDesign,
      padding = _ref.bodyDesign.padding,
      inheritDialogMaxWidth = _ref.individualLayout.inheritDialogMaxWidth,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      _ref$renderedHeights = _ref.renderedHeights,
      headerContainer = _ref$renderedHeights.headerContainer,
      footerContainer = _ref$renderedHeights.footerContainer,
      individualPrivacyOpen = _ref.individualPrivacyOpen,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  var useType = bannerInlineStylesScale(mobile, pageRequestUuid4, type, "banner");
  var useBorderWidth = bannerInlineStylesScale(mobile, pageRequestUuid4, borderWidth);
  var usePadding = bannerInlineStylesScale(mobile, pageRequestUuid4, padding, undefined, true);

  var _hexToRgb = hexToRgb(fontColor),
      r = _hexToRgb.r,
      g = _hexToRgb.g,
      b = _hexToRgb.b;

  var width = useType === "dialog" ? +dialogMaxWidth - usePadding[1] - usePadding[3] - useBorderWidth * 2 : BUTTONS_WIDTH;
  var style = (_style = {
    background: bg,
    paddingTop: useType === "banner" || individualPrivacyOpen ? +usePadding[0] : 10,
    paddingBottom: !individualPrivacyOpen ? +usePadding[2] : 10,
    float: useType === "banner" || individualPrivacyOpen ? "right" : undefined,
    maxWidth: width,
    width: width,
    margin: useType === "dialog" && individualPrivacyOpen && !inheritDialogMaxWidth ? "0 0 10px 10px" : undefined,
    // position: individualPrivacyOpen ? "sticky" : undefined, This is done by `body.tsx#usePlainCss`
    position: individualPrivacyOpen ? undefined : "sticky"
  }, _defineProperty(_style, individualPrivacyOpen ? "top" : "bottom", individualPrivacyOpen ? +headerContainer + bannerInlineStylesScale(mobile, pageRequestUuid4, headerDesign.borderWidth) : +footerContainer + bannerInlineStylesScale(mobile, pageRequestUuid4, footerDesign.borderWidth)), _defineProperty(_style, "zIndex", 1), _defineProperty(_style, "display", "flex"), _defineProperty(_style, "flexWrap", "wrap"), _defineProperty(_style, "transition", "box-shadow ease-in-out .1s"), _defineProperty(_style, "--boxShadowShowScroll", "0 -15px 15px -15px rgba(".concat(r, ", ").concat(g, ", ").concat(b, ", 0.3)")), _style);
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-tb-right",
    style: style
  };
}

export { bannerInlineStylesRightSide };