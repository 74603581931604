function matchingSelector(element, selector) {
  if (!element || !element.parentElement) {
    return false;
  }

  var matchingElements = element.parentElement.querySelectorAll(selector);

  for (var matchIdx in matchingElements) {
    if (matchingElements[matchIdx] === element) {
      return true;
    }
  }

  return false;
}

export { matchingSelector };